$(function(){
	initInput();
	$.views.settings.allowCode(true);
	$('.js-subscribe-open').on('click',function(){
		$('#reg-form-switcher').prop('checked',true)
	});

})

initHeader();
function initHeader(){
	initFixMenu();
	initBasketStatistics();

	function initFixMenu() {
		window.addEventListener('scroll', function () {
			var howMuchScroll = window.pageYOffset;
			if (window.innerWidth>1024){
				if(howMuchScroll>=$('.js-pre-header').height()){
					$('#header').addClass('fixed');
				}else{
					$('#header').removeClass('fixed');
				}
			}else{
				if(howMuchScroll>=$('.js-h__banner').height()){
					$('#header').addClass('fixed');
				}else{
					$('#header').removeClass('fixed');
				}
			}
		});
	}
	function initBasketStatistics(){
		$('.js-open-basket-modal').click(function(){
			var parent= $(this).closest('#bx_basketFKauiI'),
				productList = $('.js-basket-modal-products-list .js-basket-product-item',parent),
				items = [];

			if(!productList.length){
				return true;
			}

			productList.each(function(){
				var productData = $(this),
					productId = productData.data('product-id'),
					productName = productData.data('product-name'),
					productBrand = productData.data('product-brand'),
					productPrice = productData.data('product-price'),
					productMainCategory = productData.data('product-main-category'),
					productCategory = productData.data('product-category'),
					productQuantity = productData.data('quantity'),
					productListName = sessionStorage.getItem('product_item_list_name_'+productId) ? sessionStorage.getItem('product_item_list_name_'+productId) : '',
					productListId = sessionStorage.getItem('product_item_list_id_'+productId) ? sessionStorage.getItem('product_item_list_id_'+productId) : '',
					productPositionInList = sessionStorage.getItem('product_index_'+productId) ? sessionStorage.getItem('product_index_'+productId) : '';

				items.push({
					item_name: productName,
					item_id: productId,
					price: productPrice,
					item_brand: productBrand,
					item_category: productMainCategory,
					item_category2: productCategory,
					item_category3: '',
					item_list_name: productListName,//�������� ������ �������
					item_list_id: productListId,// id ������ �������
					index: productPositionInList,
					quantity: productQuantity
				});
			});

			window.dataLayer = window.dataLayer || [];
			dataLayer.push({ ecommerce: null });
			dataLayer.push({
				event: 'view_cart',
				ecommerce: {
					currency: 'RUB',
					value: $('.js-basket-modal-total').text(),
					items: items,
				}
			});
		});
	}
}

function initInput(){
	initInputChange();
	function initInputChange() {
		if ($('body').attr('data-init-init')) {
			return true;
		}
		$('body').attr('data-init-init',1);
		$('body').on('change', 'input[type="text"],input[type="tel"],input[type="email"]', function () {
			$(this).attr('value', $(this).val());
		});
	}

	$('.js-init-input').each(function(){
		var hasLabel = $(this).next()[0].tagName === 'LABEL',
			placeholder = $(this).attr('placeholder'),
			elementName = $(this).attr('name');

		if(hasLabel || !placeholder || !elementName) {
			return true;
		}
		var elementId = getId(this);

		$( '<label for="'+elementId+'">'+$(this).attr('placeholder')+'</label>' ).insertAfter( $(this) );
		$(this).attr('id',elementId).removeAttr('placeholder').addClass('init-input').val($(this).attr('value'));

		function getId(el){
			if($(el).attr('id')){
				return $(el).attr('id');
			}
			return getUniqueId($(el).attr('name'),1)

			function getUniqueId(name,counter){
				counter = counter || 1;
				if($('#'+name+'-'+counter).length){
					counter++;
					return getUniqueId(name,counter);
				}
				return name+'-'+counter;
			}
		}
	});
}