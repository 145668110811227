document.addEventListener("DOMContentLoaded", function(){
	initReviews();
});

function initReviews() {
	if(!$('#main.content-reviews').length){
		return true;
	}
	$('.js-reviews-show-more').click(function (e) {
		e.preventDefault();
		var form = $('#reviews-list-form');
		if (parseInt(form.attr('loading')) == 1) {
			return false;
		}
		form.attr('loading', 1);
		var page_el = form.find('input[name=page]');
		page_el.val(parseInt(page_el.val()) + 1);
		formChangeFunc(form, true, true);
		// if ( parseInt( $(this).data('pages') ) >= parseInt( page_el.val() ) ) {
		// 	$(this).hide();
		// }
	});
}